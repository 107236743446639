import React, { useEffect } from "react";

import "aos/dist/aos.css";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/seo";

import ListenerUspMedia from "../components/listenerUspMedia";
import NotFoundPage from "./404";

import GetWebpFormat from "../utils/getWebpFormat";

const FeaturesPage = (context) => {
  useEffect(() => {
    window.scrollTo(0, 0);

    const AOS = require("aos");
    AOS.init({
      duration: 1800,
      once: true
    });

    if (AOS) {
      AOS.refresh();
    }

    const videoPoster = document.querySelector('.js-videoPoster');

    if (videoPoster) {
      document.querySelector('.js-videoPoster').onclick = ((event) => {
        event.preventDefault();

        const $currentElement = event.target.closest('.js-videoPoster');
        videoPlay($currentElement.closest('.js-videoWrapper'));
      });
    }

    if (AOS) {
      AOS.refresh();
    }
  }, []);

  function videoPlay($wrapper) {
    const $iframe = $wrapper.querySelector('.js-videoIframe');

    $wrapper.classList.add('videoWrapperActive');
    $iframe.setAttribute('src', $iframe.dataset['src'])
  }

  const featuresPageConfigs = context.pageContext.node;
  const headerData = context.pageContext.headerConfigurations;
  const footerData = context.pageContext.footerConfigurations;

  if (!featuresPageConfigs) return <NotFoundPage />;

  return (
    <Layout headerData={headerData} footerData={footerData}>
      <section className="features-page">
        { featuresPageConfigs.seo && <SEO page={`features/${featuresPageConfigs.slug}`} seo={featuresPageConfigs.seo} /> }
        <section className="features-hero-section"
          style={{backgroundColor: `${featuresPageConfigs.hero_section.background_color}`}}
        >
          <div className="content-layer">
            <h1 className="h1"
              style={{color: `${featuresPageConfigs.hero_section.text_color}`}}
            >
              {featuresPageConfigs.hero_section.section_heading}
            </h1>

            <p className="caption"
              style={{color: `${featuresPageConfigs.hero_section.text_color}`}}
            >
              {featuresPageConfigs.hero_section.section_description}
            </p>

            <div className="cta-wrapper">
              <a href={featuresPageConfigs.hero_section.cta_button.url}
                className="cta-button"
                target={featuresPageConfigs.hero_section.cta_button.target}
              >
                {featuresPageConfigs.hero_section.cta_button.button_text}
              </a>
            </div>
          </div>

            <div className="background-layer">
              <img data-aos="animate-triangle-pattern-sand-pricing" src="https://res.cloudinary.com/listener-cms/image/upload/v1610627301/features_foreground_105cd61721.svg" className="graphic-art" role="presentation" alt="" />
            </div>
        </section>

        <section className="features-wrapper">
          <ul className="features">
            {
              featuresPageConfigs.feature_usps.listener_usps.map((listenerUspSection, index) => {
                return (
                  <li className="feature" key={index}>
                    { listenerUspSection.media &&
                      <div className="image-wrapper">
                        {ListenerUspMedia(listenerUspSection.media.url, listenerUspSection.heading)}
                      </div>
                    }

                    <div className="text-wrapper">
                      <h4>{listenerUspSection.heading}</h4>
                      <p>{listenerUspSection.description}</p>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </section>

        { featuresPageConfigs.features_testimonials &&
          <section className="testimonials-section">
            <div className="background-layer">
              <img className="stripes" alt="stripes" src="https://res.cloudinary.com/listener-cms/image/upload/v1609144189/beige_rectangle_background_obfa8t.svg" />
              <img className="triangle-pattern-sand" alt="triangle-pattern-sand" src="https://res.cloudinary.com/listener-cms/image/upload/v1608802011/triangle-pattern-sand_hax8s2.svg" />
            </div>

            <div className="content-layer">
              <h4>{featuresPageConfigs.features_testimonials.section_heading}</h4>

              <div className="testimonial-videos">
                {
                  featuresPageConfigs.features_testimonials.testimonial_videos.map((testimonial, index) => {
                    return (
                      <div className="video-wrapper videoWrapper169 js-videoWrapper" key={`testimonial_${index}`}>
                        <iframe className="videoIframe js-videoIframe"
                          title="testimonial-video"
                          src=""
                          frameBorder="0"
                          allowtransparency="true"
                          autoPlay={true}
                          allowFullScreen
                          allow="autoplay"
                          data-src={`https://www.youtube.com/embed/${testimonial.video_id}?autoplay=1&modestbranding=1&rel=0&hl=sv`}
                        ></iframe>

                        <button className="videoPoster js-videoPoster"
                          aria-label="Video Background Image"
                          style={{backgroundImage: `url(${GetWebpFormat(testimonial.cover_image_desktop.url)})`}}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="159.933" height="159.933" viewBox="0 0 159.933 159.933"><g transform="translate(-520 -258)"><circle opacity="0.6" cx="79.967" cy="79.967" r="79.967" transform="translate(520 258)" /><path fill="#fff" d="M111.652,76.751,63.974,50.162a2.086,2.086,0,0,0-3.1,1.821v53.849a2.086,2.086,0,0,0,3.1,1.821L111.65,81.12a2.5,2.5,0,0,0,0-4.369" transform="translate(519 259.084)"/></g></svg>
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </section>
        }

        <section className="feature-links-section">
          <div className="content-layer">
            <h3 className="h1">{featuresPageConfigs.more_features.heading}</h3>
            <ul className="feature-links">
              {
                featuresPageConfigs.more_features.links.map((link, index) => {
                  return (
                    <li key={`more-links-${index}`}>
                      <a href={link.url} target={link.target}>
                        <span>{link.text}</span>
                        <span className="learn-more"><em>learn more</em><svg xmlns="http://www.w3.org/2000/svg" width="27.947" height="23.799" viewBox="0 0 27.947 23.799"><path fill="#73c6d5" d="M1540.711,392.926l-9.9-9.9a2,2,0,0,0-2.829,2.828l6.538,6.538H1515.3a1.947,1.947,0,1,0,0,3.894h19.224l-6.538,6.539a2,2,0,0,0,2.829,2.828l9.9-9.9A2,2,0,0,0,1540.711,392.926Z" transform="translate(-1513.35 -382.44)"/></svg></span>
                      </a>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </section>

        {
          featuresPageConfigs.cta_section &&
          <section className="cta-jumbo-section">
            <h5>{featuresPageConfigs.cta_section.caption}</h5>
            <a href={featuresPageConfigs.cta_section.url} className="cta-button" target={featuresPageConfigs.cta_section.target}>
              {featuresPageConfigs.cta_section.button_text}
            </a>
          </section>
        }
      </section>
    </Layout>
  )
}

export default FeaturesPage;
